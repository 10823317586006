<template>
    <b-row>
       <b-col class="col-12">
        <form action="#">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <textarea v-model="vmGpathsNotesFormData.counsellor_notes" class="textarea form-control"></textarea>
            </div>
          </div>
          <div class="form-group">
            <button v-if="vmGpathsNotesFormData.counsellor_notes.length > 0" type="button" class="btn btn-primary float-right mb-2" @click="gpath_notesEdit()">
                {{cvSubmitBtn}}
            </button>
          </div>
        </form>
       </b-col>
       <b-col>
        <iq-card v-for="(notes,index) of gpathNotesObjList" :key="index+1">
          <p class="p-4">{{ notes.counsellor_notes }}</p>
        </iq-card>
       </b-col>
    </b-row>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import ApiResponse from "../../../Utils/apiResponse"
import { GPathSessions } from "../../../FackApi/api/GPathSession.js"

export default {
  name: "GPathNotes",
  props: {
    propGpathsSessionDetailsObj: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      cvNameLabel: "notes",
      vmGpathsNotesFormData: Object.assign({}, this.initFormData()),
      cvSubmitBtn: "Update",
      gide_user_id: "",
      whereFilter: {},
      gpathNotesObjList: [],
      Addnotes: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()

    if (this.propGpathsSessionDetailsObj && this.propGpathsSessionDetailsObj.counsellor_notes) {
      this.vmGpathsNotesFormData.counsellor_notes = this.propGpathsSessionDetailsObj.counsellor_notes
    }
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "gps_id": "",
        "counsellor_notes": ""
      }
    },
    /**
     * gpath_notesEdit
     */
    async gpath_notesEdit () {
      try {
        let payload = {
          gps_id: this.propGpathsSessionDetailsObj.gps_id,
          counsellor_notes: this.vmGpathsNotesFormData.counsellor_notes
        }

        this.cvLoadingStatus = true
        let gpathsNotesAddResp = await GPathSessions.gpathNotesEdit(this, payload)
        await ApiResponse.responseMessageDisplay(this, gpathsNotesAddResp)

        if (gpathsNotesAddResp && !gpathsNotesAddResp.resp_status) {
          return false
        }

        this.$emit("emitCloseGPathNotesModal", payload)
      }
      catch (err) {
        console.error("Exception occurred at gpath_notesEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style>
  .textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
